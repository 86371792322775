import React from "react";
import { Helmet } from "react-helmet";
import Header from "../Component/Header/Header";
import Footer from "Component/Footer/Footer";
import ContentPage from "Pages/Content";

export default function Contenttheme() {
  return (
    <>
      <Helmet>
        <title>SetVR - XL</title>
        <meta
          name="description"
          content="Welcome to my awesome website homepage."
        />
        <meta property="og:title" content="Home | SetVR - XL" />
        <meta
          property="og:description"
          content="Welcome to my awesome website homepage."
        />
        <meta property="og:url" content="https://www.mywebsite.com/" />
        <meta
          property="og:image"
          content="https://www.mywebsite.com/homepage-image.jpg"
        />
      </Helmet>
      <Header />
      <ContentPage />
      <Footer />
    </>
  );
}
