import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { Button, Form, FormGroup, Input } from "reactstrap";
import { useLocation } from "react-router-dom";

// images

export default function Header() {
  const location = useLocation();

  const [nav, setNav] = useState(false);

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);
  const handleClickScroll = (e) => {
    e.preventdefault();
    const element = document.getElementById("Home", "Contactus");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <section className={scroll ? "header-main  fixed-header" : "header-main"}>
        <Container>
          <div className="header">
            <nav class="navbar navbar-expand-lg">
              <div class="container-fluid">
                <Link to="/" class="navbar-brand">
                  <img
                    src="/images/logo.png"
                    alt="logo"
                    className="img-fluid"
                  />
                </Link>
                <button
                  class="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => setNav(!nav)}
                >
                  <span className="bar"></span>
                  <span className="bar"></span>
                  <span className="bar"></span>
                </button>
                <div
                  className={
                    nav
                      ? "collapse navbar-collapse show"
                      : "collapse navbar-collapse"
                  }
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav scroll-menu ms-auto mb-2 mb-lg-0">
                    <Button
                      onClick={() => setNav(false)}
                      className="close-menu"
                    >
                      <svg
                        aria-hidden="true"
                        role="img"
                        className="iconify iconify--gg"
                        width="28"
                        height="28"
                        preserveAspectRatio="xMidYMid meet"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#111"
                          d="M6.225 4.811a1 1 0 0 0-1.414 1.414L10.586 12L4.81 17.775a1 1 0 1 0 1.414 1.414L12 13.414l5.775 5.775a1 1 0 0 0 1.414-1.414L13.414 12l5.775-5.775a1 1 0 0 0-1.414-1.414L12 10.586L6.225 4.81Z"
                        />
                      </svg>
                    </Button>
                    {location?.pathname !== "/page" ? (
                      <>
                        <li className="nav-item">
                          <a
                            href="/"
                            className="nav-link gradient-text"
                            aria-current="page"
                          >
                            Home
                          </a>
                        </li>
                        <li className="nav-item">
                          <Link
                            to="/blog"
                            href="#Contactus"
                            className="nav-link gradient-text"
                          >
                            Blog
                          </Link>
                        </li>

                        <li className="nav-item">
                          <Link
                            to="/video"
                            href="#Contactus"
                            className="nav-link gradient-text"
                          >
                            SetVR Video
                          </Link>
                        </li>

                        <li className="nav-item">
                          <a
                            href="#Contactus"
                            className="nav-link gradient-text"
                          >
                            Contact Us
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="#Aboutus" className="nav-link gradient-text">
                            About Us
                          </a>
                        </li>

                        <li className="nav-item">
                          <a
                            href="https://www.meta.com/en-gb/experiences/setvr-xl-unleashed/7497491080333401/"
                            target="_blank"
                            class="nav-link meta_store"
                          >
                            <div className="metaButton text-start">
                              <p>BUY ON</p>
                              <p>META QUEST STORE</p>
                            </div>
                            <span>{metaicon}</span>
                          </a>
                        </li>
                      </>
                    ) : null}
                  </ul>
                </div>
              </div>
            </nav>

            <div className="add-wallet-wrap">
              {/* <div className="wallet-section">
                
                <Link to="/" className="nav-link sigb-up-link">
                  Buy SETVR 
                </Link>
              </div> */}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

// svg

const metaicon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="22"
    viewBox="0 0 640 512"
  >
    <path
      fill="currentColor"
      d="M640 317.9c0 91.3-39.4 148.5-110.3 148.5c-62.6 0-95.8-34.6-156.9-136.6l-31.4-52.6c-8.3-12.5-14.5-24.2-21.2-35c-20.1 33.8-47.1 83-47.1 83c-67 116.6-104.6 141.2-156.9 141.2C43.42 466.4 0 409.1 0 320.5c0-143 79.78-278.1 183.9-278.1c50.2 0 93.8 24.68 144.8 89.5c37.1-50.1 78.1-89.5 130.6-89.5c99.1 0 180.7 125.7 180.7 275.5M287.4 192.2c-42.9-62.1-70.9-80.5-104.4-80.5c-61.9 0-113.78 106.1-113.78 210c0 48.5 18.48 75.7 49.58 75.7c30.2 0 49-19 103.2-103.8c0 0 24.7-39.1 65.4-101.4m243.8 205.2c32.2 0 46.9-27.5 46.9-74.9c0-124.2-54.3-225.42-123.2-225.42c-33.2 0-61.1 25.92-94.9 78.02c9.4 13.8 19.1 29 29.3 45.4l37.5 62.4c58.7 94.1 73.5 114.5 104.4 114.5"
    />
  </svg>
);
