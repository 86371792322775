import { SettingsContext } from "context/SettingsProvider";
import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Fade } from "react-reveal";

const defaultData = {
  heading: "Bring fun structured practice into your daily life",
  subHeading: "/images/blue-bedge.jpeg",
  backgroundImage: "",
  forgroundImages: [
    {
      name: "Virtual Dojo",
      title: "Warm Up",
      image: "/images/funboxone.png",
    },
    {
      name: "Virtual Attackers",
      title: "Fight",
      image: "/images/funboxtwo.png",
    },
    {
      name: "Yoga and more",
      title: "Warm Up",
      image: "/images/funboxthree.png",
    },
  ],
};

const Funstructure = () => {
  const [sectionThree, setSectionThree] = useState(defaultData);

  const [settings] = useContext(SettingsContext);
  useEffect(() => {
    if (settings?.sectionThree) {
      const data = {
        heading: settings?.sectionThree?.heading || defaultData?.heading,
        subHeading:
          settings?.sectionThree?.subHeading || defaultData?.subHeading,
        backgroundImage:
          settings?.sectionThree?.backgroundImage ||
          defaultData?.backgroundImage,
        forgroundImages: settings?.sectionThree?.forgroundImages?.length
          ? settings?.sectionThree?.forgroundImages
          : defaultData?.forgroundImages,
      };
      setSectionThree(data);
    }
  }, [settings]);

  return (
    <Fade>
      {/* <section className="Funstructure_col comon_pad">
        <Container>
          <div className="funtstructure_head text-center">
            <h2>{sectionThree?.heading}</h2>

            <a href="https://www.meta.com/en-gb/experiences/setvr-xl-unleashed/7497491080333401/" target="_blank">
            <div className="img-coverd">
              <img
                src={sectionThree?.subHeading}
                alt=""
                className="img-fluid"
                />
            </div>
                </a>
            <div className="linkCovered mt-4">
              <img
                src="images/logob.png"
                alt=""
                style={{ height: 135 }}
                className="img-fluid object-fit-contain"
              />
              <p className="m-0 pt-3">
                Visit our new online magazine:{" "}
                <a
                  style={{ color: "#2182bf" }}
                  href="https://sites.google.com/setvrxl.com/virtually-unleashed-online-mag/home"
                  target="_blank"
                  className=""
                >
                  “Virtually Unleashed! “
                </a>
              </p>
            </div>
          </div>

          <div className="funstructure_boxes mt-4">
            <Row>
              {sectionThree?.forgroundImages?.map((item) => (
                <Col lg={4} md={6} sm={12}>
                  <div className="box_common">
                    <div className="funbox_img mb-2">
                      <img src={item?.image} alt="" className="img-fluid" />
                    </div>
                    <div className="funbox_cont">
                      <h4>{item?.title}</h4>
                      <p>{item?.name}</p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </section> */}

      <section className="Funstructure_col comon_pad">
        <Container>
          <h2 className="py-lg-5 py-3 text-uppercase">
            {sectionThree?.heading}
          </h2>

          <div className="funtstructure_head text-center d-flex">
            <Row>
              <Col lg={5} md={5} sm={12}>
                <div className="funtstructure_head_img">
                  <img
                    src="images/logob.png"
                    alt=""
                    style={{ height: 185 }}
                    className="img-fluid object-fit-contain"
                  />
                </div>
              </Col>

              <Col lg={7} md={7} sm={12}>
                <div className="funtstructure_head_right text-start mt-3 text-center">
                  <div className="img-coverd">
                    <div className="magazine">
                      <h3>Visit our new online magazine</h3>
                    </div>
                    <a
                      href="https://sites.google.com/setvrxl.com/virtually-unleashed-online-mag/home"
                      target="_blank"
                    >
                      <img
                        src={sectionThree?.subHeading}
                        alt=""
                        className="img-fluid"
                      />
                    </a>
                  </div>
                  {/* <div className="linkCovered">
                    <p className="m-0 pt-3">
                      
                      <a
                        style={{ color: "#2182bf" }}
                        href="https://sites.google.com/setvrxl.com/virtually-unleashed-online-mag/home"
                        target="_blank"
                        className=""
                      >
                        “Virtually Unleashed! “
                      </a>
                    </p>
                  </div> */}
                </div>
              </Col>
            </Row>
          </div>

          <div className="funstructure_boxes mt-4">
            <Row>
              {sectionThree?.forgroundImages?.map((item) => (
                <Col lg={4} md={6} sm={12}>
                  <div className="box_common">
                    <div className="funbox_img mb-2">
                      <img src={item?.image} alt="" className="img-fluid" />
                    </div>
                    <div className="funbox_cont">
                      <h4>{item?.title}</h4>
                      <p>{item?.name}</p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </section>
    </Fade>
  );
};

export default Funstructure;
