import { getContentPage } from "helpers/backend_helper";
import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { Fade } from "react-reveal";
import { useParams } from "react-router-dom";

const pages = {
  "terms-of-services": "Terms of Services",
  "privacy-policy": "Privacy Policy"
};

const ContentPage = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (slug) {
        try {
          setLoading(true);
          const response = await getContentPage(slug);
          setData(response);
        } catch (e) {
          console.error("Error fetching content:", e);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [slug]);

  return (
    <Fade>
      <section className="content-page">
        <Container>
          {loading ? (
            <div className="d-flex justify-content-center mt-5">
              <Spinner color="#000" />
            </div>
          ) : !data ? (
            <div className="d-flex justify-content-center mt-5">
              <span>No data found!</span>
            </div>
          ) : (
            <>
              <h2>{data?.title || pages[slug]}</h2>
              <div
                className="mt-5"
                dangerouslySetInnerHTML={{ __html: data?.content }}
              ></div>
            </>
          )}
        </Container>
      </section>
    </Fade>
  );
};

export default ContentPage;
