import React, { useState } from "react";
import { Container, Row, Col, Spinner, Button } from "react-bootstrap";
import ReactPlayer from "react-player";

// Images and videos
import Featureimg from "../../../src/assets/images/benefitone.png";

import Videofullmod from "Component/Modals/Videofullmod";

const featuredata = [
  {
    title: "Castle Shot",
    img: Featureimg,
    video: "https://setvrxl-backend.s3.us-east-1.amazonaws.com/Castle+Shot.mp4",
  },
  {
    title: "Dojo Shot",
    img: Featureimg,
    video: "https://setvrxl-backend.s3.us-east-1.amazonaws.com/Dojo+Shot.mp4",
  },
  {
    title: "Japanese Shot",
    img: Featureimg,
    video:
      "https://setvrxl-backend.s3.us-east-1.amazonaws.com/Japanese+Shot.mp4",
  },
  {
    title: "VR Hands Free Fight",
    img: Featureimg,
    video:
      "https://setvrxl-backend.s3.us-east-1.amazonaws.com/VR+Hands+Free+fight.mp4",
  },
  {
    title: "VR Shot",
    img: Featureimg,
    video: "https://setvrxl-backend.s3.us-east-1.amazonaws.com/VR+Shot.mp4",
  },
  {
    title: "VR Weapon Fight",
    img: Featureimg,
    video:
      "https://setvrxl-backend.s3.us-east-1.amazonaws.com/VR+Weapon+Fight.mp4",
  },
];

const Videopage = () => {
  const [loadingStates, setLoadingStates] = useState(
    Array(featuredata.length).fill(true)
  );

  const [fullmodal, setFullmodal] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  const handleReady = (index) => {
    setLoadingStates((prev) =>
      prev.map((loading, i) => (i === index ? false : loading))
    );
  };

  const handleOpenModal = (video) => {
    setCurrentVideo(video);
    setFullmodal(true);
  };

  return (
    <>
      <Videofullmod
        show={fullmodal}
        onhide={() => setFullmodal(false)}
        currentVideo={currentVideo}
      />
      <section className="features_section">
        <Container>
          <div className="funtstructure_head text-center mb-4">
            <h1>SetVR Video</h1>
          </div>

          <div className="Feature_Card">
            <Row>
              {featuredata.map((item, index) => (
                <Col lg={4} md={6} sm={12} key={index}>
                  <div className="feature_wrapped">
                    <div className="feature_card position-relative">
                      {loadingStates[index] && (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ height: 360 }}
                        >
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </div>
                      )}
                      <ReactPlayer
                        url={item.video}
                        muted
                        playing
                        onReady={() => handleReady(index)} // Mark video as loaded
                        style={{
                          display: loadingStates[index] ? "none" : "block",
                        }}
                      />
                      {!loadingStates[index] && (
                        <Button
                          className="fullscreenbtn"
                          onClick={() => handleOpenModal(item.video)}
                        >
                          {Fullsizeicon}
                        </Button>
                      )}
                    </div>
                    <h3>{item.title}</h3>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Videopage;

const Fullsizeicon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M5 5h5V3H3v7h2zm5 14H5v-5H3v7h7zm11-5h-2v5h-5v2h7zm-2-4h2V3h-7v2h5z"
    />
  </svg>
);
