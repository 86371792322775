import React, { useContext, useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Button, Form, FormGroup, Label, Input, Spinner } from "reactstrap";
import Slider from "react-slick";
import { postContactUsData } from "helpers/backend_helper";
import { toast } from "react-toastify";
import { SettingsContext } from "context/SettingsProvider";
import ReCAPTCHA from "react-google-recaptcha";

const defaultData = {
  heading: "Ready to start your Self Enhancement Experience?",
  backgroundImage: "",
  leftHeading: "Contact Information",
  leftDescription: `Providing you with the VR tools you need to practice yoga,
  self-defense, and meditation at home or on the go!`,
  forgroundImages: [
    {
      image: "/images/contactus.png",
    },
    {
      image: "/images/slidertwo.png",
    },
    {
      image: "/images/sliderthree.png",
    },
  ],
  email: "support@setvrxl.com",
};

const Contactus = () => {
  const [sectionTen, setSectionTen] = useState(defaultData);
  const [settings] = useContext(SettingsContext);
  const [captchaValue, setCaptchaValue] = useState(null);

  useEffect(() => {
    if (settings?.sectionTen) {
      const data = {
        heading: settings?.sectionTen?.heading || defaultData.heading,
        leftHeading:
          settings?.sectionTen?.leftHeading || defaultData.leftHeading,
        leftDescription:
          settings?.sectionTen?.leftDescription || defaultData?.leftDescription,
        backgroundImage:
          settings?.sectionTen?.backgroundImage || defaultData?.backgroundImage,
        forgroundImages: settings?.sectionTen?.forgroundImages?.length
          ? settings?.sectionTen?.forgroundImages
          : defaultData?.forgroundImages,
        email: settings?.sectionTen?.email || defaultData?.email,
      };
      setSectionTen(data);
    }
  }, [settings]);

  const [fields, setFields] = useState({
    name: "",
    email: "",
    message: "",
  });

  const captchaRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const onHandleFields = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({ ...prev, [name]: value }));
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!captchaValue) {
      toast.error("Please complete the CAPTCHA!");
      return;
    }
    try {
      setLoading(true);
      const response = await postContactUsData({ ...fields, captchaValue });
      console.log("response", response);
      setLoading(false);
      if (response) {
        toast.success("Your message sent successfully!");
        setFields({
          name: "",
          email: "",
          message: "",
        });
        setCaptchaValue("");
        captchaRef.current.reset();
      }
    } catch (err) {
      toast.error(err);
      console.log("error", err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const sliderSettings = {
    dots: false,
    fade: true,
    autoplay: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section id="Contactus" className="Contactus_section comon_pad">
      <Container>
        <div className="contact_head text-center py-5">
          <h2>{sectionTen?.heading}</h2>
        </div>
        <Row>
          <Col lg={6} md={6} sm={12} className="p-0">
            <div div className="contact_wrap_left">
              <div className="contact_img">
                <Slider {...sliderSettings}>
                  {sectionTen?.forgroundImages?.map((slideimg, idx) => {
                    return (
                      <div key={idx}>
                        <img
                          src={slideimg.image}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    );
                  })}
                </Slider>
              </div>

              <div className="contact_info">
                <h4>{sectionTen?.leftHeading}</h4>
                <p>{sectionTen?.leftDescription}</p>

                <div className="contact_info_social">
                  <h5>Contact Info :</h5>
                  <ul>
                    <li>
                      Email:
                      <a href="mailto:${sectionTen.email}">
                        {" "}
                        {sectionTen?.email}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Col>

          <Col lg={6} md={6} sm={12} className="p-0">
            <div className="contact_form">
              <Form onSubmit={onSubmit}>
                <FormGroup className="mb-4">
                  <Label for="exampleEmail">Full Name</Label>
                  <Input
                    type="name"
                    name="name"
                    value={fields.name}
                    id="exampleEmail"
                    placeholder="Input your full name in here"
                    onChange={onHandleFields}
                    required
                  />
                </FormGroup>
                <FormGroup className="mb-4">
                  <Label for="exampleEmail">Email Address</Label>
                  <Input
                    type="email"
                    name="email"
                    value={fields.email}
                    id="exampleEmail"
                    placeholder="Input your email address in here"
                    onChange={onHandleFields}
                    required
                  />
                </FormGroup>
                <FormGroup className="mb-4">
                  <Label for="exampleEmail">Messages</Label>
                  <Input
                    type="textarea"
                    value={fields.message}
                    name="message"
                    id="exampleEmail"
                    placeholder="Write your messages in here"
                    onChange={onHandleFields}
                    required
                  />
                </FormGroup>

                <ReCAPTCHA
                  ref={captchaRef}
                  sitekey="6LcNCGEqAAAAAFMiz1aIj0um8IPdyuy4HIWIPiad"
                  onChange={handleCaptchaChange}
                />

                <div className="d-flex justify-content-end mt-5">
                  <Button
                    type="submit"
                    className="sentbtn bg-dark border-0 py-2 px-4 rounded"
                  >
                    {loading && <Spinner size="sm" className="me-2" />} Send
                    Messages
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Contactus;
