import { SettingsContext } from "context/SettingsProvider";
import React, { useContext, useEffect, useState } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";


const defaultData = {
  leftHeading: "Repeat Daily for Benefits",
  rightSubHeading: "Get SETVR’s focus on game-like exercise helps you:",
  rightDescription: "",
  images: [
    {
      image: "/images/benefitone.png",
      name: "Burn fat, build lean muscle",
    },
    {
      image: "/images/benefittwo.png",
      name: "Sharpen your skills as you build muscle memory.",
    },
    {
      image: "/images/benefitthree.png",
      name: "Practice your self defense moves and build up your stamina.",
    },
  ]
};


const Benefits = () => {
  const [sectionFive, setSectionFour] = useState(defaultData);

  const [settings] = useContext(SettingsContext);

  useEffect(() => {
    if (settings?.sectionFive) {
      const data = {
        rightSubHeading: settings?.sectionFive?.rightSubHeading || defaultData.rightSubHeading,
        leftHeading: settings?.sectionFive?.leftHeading || defaultData.leftHeading,
        rightDescription: settings?.sectionFive?.rightDescription || defaultData?.rightDescription,
        images: settings?.sectionFive?.images?.length ? settings?.sectionFive?.images : defaultData?.images
      }
      setSectionFour(data);
    }
  }, [settings])

  return (
    <section className="Benefits_sec comon_pad">
      <Container>
        <div className="benefitd_heaing">
          <h3>{sectionFive?.leftHeading}</h3>

          <div className="learm_moresection">
            <p>{sectionFive?.rightSubHeading}</p>

            {/* <Button className="btn-common">Learn More</Button> */}
          </div>
        </div>

        <div className="card_box">
          <Row>
            <ul className="cardbox_list m-0">
              {sectionFive?.images?.map((carditem, idx) => {
                return (
                  <li>
                    <div className="card_wrap_box position-relative" key={idx}>
                      <div className="cardIMg">
                        <img
                          src={carditem.image}
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="card_contetn text-center">
                        <p>{carditem.name}</p>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default Benefits;
