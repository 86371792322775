import { SettingsContext } from "context/SettingsProvider";
import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import btnImg from "../../assets/images/explore.png";
import videoExplore from "../../assets/images/exploreVirtualWorld.mp4";

const defaultData = {
  heading: "Bring fun structured practice into your daily life",
  subHeading: "/images/blue-bedge.jpeg",
  backgroundImage: "",
  forgroundImages: [
    {
      name: "Virtual Dojo",
      title: "Warm Up",
      image: "/images/funboxone.png",
    },
    {
      name: "Virtual Attackers",
      title: "Fight",
      image: "/images/funboxtwo.png",
    },
    {
      name: "Yoga and more",
      title: "Warm Up",
      image: "/images/funboxthree.png",
    },
  ],
};

const VideoNewSec = () => {
  const [sectionFour, setSectionFour] = useState(defaultData);

  const [settings] = useContext(SettingsContext);

  useEffect(() => {
    if (settings?.sectionFour) {
      const data = {
        leftVideo: settings?.sectionFour?.leftVideo || defaultData?.leftVideo,
        rightHeading:
          settings?.sectionFour?.rightHeading || defaultData?.rightHeading,
        rightDescription:
          settings?.sectionFour?.rightDescription ||
          defaultData?.rightDescription,
        bottomRightSubHeading:
          settings?.sectionFour?.bottomRightSubHeading ||
          defaultData?.bottomRightSubHeading,
      };
      setSectionFour(data);
    }
  }, [settings]);
  return (
    <Fade>
      <section className="Excercise_Sec comon_pad" style={{ paddingTop: 150 }}>
        <Container>
          <Row className="align-items-center">
            <Col lg={6} md={6} sm={12}>
              <div className="leftvedio_content">
                <h3>
                  Experience the Virtual World – Explore Our VR Game Gallery!
                </h3>

                <div className="img-coverd2">
                  <Link to="/video" target="_blank">
                    <img
                      src={btnImg}
                      alt=""
                      className="img-fluid rounded"
                      style={{ height: 60 }}
                    />
                  </Link>
                </div>
                {/* <p className="five_line_down"><b>S</b>elf <b>E</b>nhancement <b>T</b>raining VR</p> */}

                {/* <Button className="btn-common mt-5">Learn More</Button> */}
              </div>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <div className="leftvedio_here position-relative">
                <video height="400" autoPlay loop muted controls>
                  <source src={videoExplore} type="video/mp4" />
                </video>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fade>
  );
};

export default VideoNewSec;
