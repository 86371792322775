import { SettingsContext } from "context/SettingsProvider";
import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { defaultData } from "./kickstarter";
import img1 from "../../assets/images/iwt1.png";
import img2 from "../../assets/images/iwt2.png";
import img3 from "../../assets/images/iwt3.png";

const Whatissetvr = () => {
  const [sectionTwo, setSectionTwo] = useState(defaultData);
  const [settings] = useContext(SettingsContext);

  useEffect(() => {
    if (settings?.sectionTwo) {
      const data = {
        question: settings?.sectionTwo?.question || defaultData?.question,
        description:
          settings?.sectionTwo?.description || defaultData?.description,
        backgroundImage:
          settings?.sectionTwo?.backgroundImage || defaultData?.backgroundImage,
        leftForgroundImage:
          settings?.sectionTwo?.leftForgroundImage ||
          defaultData?.leftForgroundImage,
        rightForgroundImage: settings?.sectionTwo?.rightForgroundImage?.length
          ? settings?.sectionTwo?.rightForgroundImage
          : defaultData?.rightForgroundImage,
        sliderBackgroundmage:
          settings?.sectionTwo?.sliderBackgroundmage ||
          defaultData?.sliderBackgroundmage,
        afterSliderStar:
          settings?.sectionTwo?.afterSliderStar || defaultData?.afterSliderStar,
      };

      setSectionTwo(data);
    }
  }, [settings]);

  return (
    <section className="whatissetvr comon_pad">
      <Container>
        <div className="setvr_content">
          <h1>
            {/* <span>
              {sectionTwo?.question?.slice(0, 2)}
            </span> */}
            {sectionTwo?.question}
          </h1>

          {/* <p>
            {sectionTwo?.description}{" "}
          </p> */}

          {/* <div
            className="mt-5"
            dangerouslySetInnerHTML={{ __html: sectionTwo?.description }}
          ></div> */}
          <Row className="py-3 iwt align-items-center">
            <Col md="7" className="my-2">
              <p className="px-0">
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: "Merriweather,serif" }}>
                    <span style={{ color: "#0e101a" }}>
                      Imagine a world where you can hone your{" "}
                    </span>
                  </span>
                </span>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: "Merriweather,serif" }}>
                    <span style={{ color: "#0e101a" }}>
                      <strong>martial arts </strong>
                    </span>
                  </span>
                </span>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: "Merriweather,serif" }}>
                    <span style={{ color: "#0e101a" }}>
                      skills—karate, kung fu, Boxing, Taekwondo and more—to
                      improve your fitness and have a blast simultaneously. That
                      world is now a reality with SetVR-XL. This immersive
                      virtual reality experience offers a unique blend of gaming
                      and martial arts training, providing a fun and engaging
                      way to enhance your physical and mental abilities.
                    </span>
                  </span>
                </span>
              </p>
              <p className="px-0">
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: "Merriweather,serif" }}>
                    <span style={{ color: "#0e101a" }}>
                      SetVR-XL is an intriguing, visually captivating, and
                      stunningly lifelike game with a hidden talent! That talent
                      is the opportunity to enhance one's physical fitness,
                      muscle memory, reflexes, and stamina and sharpen
                      mindfulness. Anyone interested in practicing self-defense
                      will rise to the challenges in the game as they enjoy the
                      many chances to improve their techniques and fitness
                      levels.
                    </span>
                  </span>
                </span>
              </p>
            </Col>
            <Col md="5" className="my-2 text-center imgWrpper">
              <img src={img2} alt="" className="img-fluid rounded" />
            </Col>
          </Row>
          <Row className="py-3 iwt align-items-center">
            <Col md="5" className="my-2 text-center imgWrpper">
              <img src={img1} alt="" className="img-fluid rounded" />
            </Col>
            <Col lg="7" className="my-2">
              <p className="px-0">
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: "Merriweather,serif" }}>
                    <span style={{ color: "#0e101a" }}>
                      Beyond the combat, SetVR-XL also incorporates yoga and
                      meditation exercises to help you relax and improve your
                      focus. This comprehensive approach ensures you get a
                      physical workout and nurtures your mental well-being.
                    </span>
                  </span>
                </span>
              </p>
              <p className="px-0">
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: "Merriweather,serif" }}>
                    <span style={{ color: "#0e101a" }}>
                      <span style={{ backgroundColor: "#ffffff" }}>
                        Discover a new way to train and stay fit with SetVR-XL's
                        immersive virtual reality platform, which combines
                        martial arts, gaming, and mindfulness for a
                        comprehensive self-defense experience.
                      </span>
                    </span>
                  </span>
                </span>
              </p>
              <p className="px-0">
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: "Merriweather,serif" }}>
                    <span style={{ color: "#0e101a" }}>
                      <strong>Key Benefits of SetVR-XL:</strong>
                    </span>
                  </span>
                </span>
              </p>
              <ul className="px-0">
                <li style={{ listStyleType: "disc" }}>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: "Merriweather,serif" }}>
                      <span style={{ color: "#0e101a" }}>
                        <strong>Enhanced Physical Fitness: </strong>
                      </span>
                    </span>
                  </span>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: "Merriweather,serif" }}>
                      <span style={{ color: "#0e101a" }}>
                        Improve your strength, stamina, and flexibility.
                      </span>
                    </span>
                  </span>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: "Merriweather,serif" }}>
                      <span style={{ color: "#0e101a" }}>
                        <strong>Sharpened Martial Arts Skills: </strong>
                      </span>
                    </span>
                  </span>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: "Merriweather,serif" }}>
                      <span style={{ color: "#0e101a" }}>
                        Practice various techniques and develop your reflexes
                        with{" "}
                      </span>
                    </span>
                  </span>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: "Merriweather,serif" }}>
                      <span style={{ color: "#0e101a" }}>
                        <strong>VR self defense training.</strong>
                      </span>
                    </span>
                  </span>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: "Merriweather,serif" }}>
                      <span style={{ color: "#0e101a" }}>&nbsp;</span>
                    </span>
                  </span>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: "Merriweather,serif" }}>
                      <span style={{ color: "#0e101a" }}>
                        <strong>Stress Relief and Mental Focus</strong>
                      </span>
                    </span>
                  </span>
                  <span style={{ fontSize: "11pt" }}>
                    <span style={{ fontFamily: "Merriweather,serif" }}>
                      <span style={{ color: "#0e101a" }}>
                        : Enjoy the benefits of yoga and meditation.
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
            </Col>
          </Row>
          <Row className="py-3 iwt align-items-center">
            <Col md="7" className="my-2">
              <p className="px-0">
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: "Merriweather,serif" }}>
                    <span style={{ color: "#0e101a" }}>
                      <strong>Convenient and Accessible: </strong>
                    </span>
                  </span>
                </span>
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: "Merriweather,serif" }}>
                    <span style={{ color: "#0e101a" }}>
                      Train from the comfort of your own home.
                    </span>
                  </span>
                </span>
              </p>
              <p className="px-0">
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: "Merriweather,serif" }}>
                    <span style={{ color: "#0e101a" }}>
                      SetVR-XL was developed by a team of experts, all
                      blackbelts, with decades of experience in martial arts and
                      technology. The passion for the sport is evident in the
                      quality and realism of the game. Come & have fun in the
                      new and exciting shooting gallery as you exercise your
                      hand and eye coordination, as well as exercise your powers
                      of concentration
                    </span>
                  </span>
                </span>
              </p>
              <p className="px-0">
                <span style={{ fontSize: "11pt" }}>
                  <span style={{ fontFamily: "Merriweather,serif" }}>
                    <span style={{ color: "#0e101a" }}>
                      So, whether you're a seasoned martial artist or just
                      starting your journey, SetVR-XL offers a fun and effective
                      way to take your training to the next level. Join the
                      virtual revolution and unleash your inner warrior today!
                    </span>
                  </span>
                </span>
              </p>
            </Col>
            <Col md="5" className="my-2 text-center imgWrpper">
              <img src={img3} alt="" className="img-fluid rounded" />
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default Whatissetvr;
