import { SettingsContext } from "context/SettingsProvider";
import { getSettings } from "helpers/backend_helper";
import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Fade } from "react-reveal";
import Slider from "react-slick";




const Homenew = () => {
  return (
    <Fade>
      <section className="NewBanner_home">
         <Container>
              HOMe
         </Container>
      </section>
    </Fade>
  );
};

export default Homenew;
