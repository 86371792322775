import { SettingsContext } from "context/SettingsProvider";
import React, { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <img src="/images/next.png" alt="" className="img-fluid" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <img src="/images/prev.png" alt="" className="img-fluid" />
    </div>
  );
}

const defaultData = {
  backgroundImage: "/images/testimonial.png",
  forgroundContent: [
    {
      title:
        "“I think the oculus quest and set VR are excellent tools to stay in physical and mental shape during this challenging time of our lives.”",
      name: "Eric Lee King of Kata, author of The Journey of an Artist",
      // testpost: "",
    },

    {
      title:
        "“SetVR has changed my attitude towards training environment and I really believe that set VR will revolutionize your training.”",
      name: "Professor Gary Lee of the Sport Karate Museum",
      // testpost: "TheSport Karate Museum",
    },

    {
      title:
        "“Highly Recommend! A great interactive training aid plus it’s fun!”",
      name: "AJ Perry",
      // testpost: "Seniour",
    },
  ]
};


const Testimonial = () => {
  const [sectionNine, setSectionNine] = useState(defaultData);
  const [settings] = useContext(SettingsContext);

  useEffect(() => {
    if (settings?.sectionNine) {
      const data = {
        backgroundImage: settings?.sectionNine?.backgroundImage || defaultData?.backgroundImage,
        forgroundContent: settings?.sectionNine?.forgroundContent ? sectionNine?.forgroundContent : defaultData?.forgroundContent,
      };

      setSectionNine(data);
    }
  }, [settings])


  const sliderSettings = {
    dots: false,
    infinite: true,
    arrows: true,
    autoplay: true,
    // speed: 1000,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <section className="Testimonial_section comon_pad"
      style={{ backgroundImage: `url(${sectionNine?.backgroundImage})` }}
    >
      <Container>
        <div className="tesimonial_slider">
          <Slider {...sliderSettings}>
            {sectionNine?.forgroundContent?.map((testitem, idx) => {
              return (
                <div>
                  <div className="testimonial text-center">
                    <p className="description">{testitem.title}</p>
                    <h3 className="heading_testing mt-4">
                      {testitem.name}
                    </h3>
                    {/* <p className="post_test mt-3">{testitem.testpost}</p> */}
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </Container>
    </section>
  );
};

export default Testimonial;
