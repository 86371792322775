import React from "react";
import { Link } from "react-router-dom";

const Blogcard = ({ posts }) => {
  return (
    <Link
      className="text-dark"
      to="/blogdetail/Hone-Self-Defense-Skills-with-SetVR-XL-Unleashed"
    >
      <div className="cardblog">
        <div className="cardImg_wrap">
          <img src={posts.image} alt="" className="img-fluid" />
        </div>
        <div className="postcard_body text-start">
          <h3>{posts.title}</h3>
          <p className="description_text">{posts.content}</p>

          {/* <div className="dateautor d-flex align-items-center justify-content-between">
          <p>{posts.date}</p>
          <p>
            <i>{posts.author}</i>
          </p>
        </div> */}
        </div>
      </div>
    </Link>
  );
};

export default Blogcard;
