import React from "react";
import Header from "../Component/Header/Header";
import Footer from "Component/Footer/Footer";
import Blogdetails from "../Pages/Blogdetails";
import { Helmet } from "react-helmet";

export default function Blogdetailstheme() {
  return (
    <>
      <Header />
      <Blogdetails title="Blog details" />
      <Footer />
    </>
  );
}
