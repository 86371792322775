import React from "react";
import { Helmet } from "react-helmet";
import Header from "../Component/Header/Header";
import Footer from "../Component/Footer/Footer";
import Videopage from "Pages/Videopage";



export default function Videotheme() {

    return (
        <>
          <Helmet>
        <title>SetVR-XL | Explore Engaging Videos for Immersive Experiences</title>
        <meta
          name="description"
          content="Discover SetVR-XL and explore engaging videos for immersive experiences that transport you into a world of creativity and exploration today!"
        />
        <meta property="og:title" content="Home | SetVR - XL" />
        <meta
          property="og:description"
          content="Discover SetVR-XL and explore engaging videos for immersive experiences that transport you into a world of creativity and exploration today!"
        />
        <meta property="og:url" content="https://www.mywebsite.com/" />
        <meta
          property="og:image"
          content="https://www.mywebsite.com/homepage-image.jpg"
        />
      </Helmet>
        <Header/>
            <Videopage/>
        <Footer/>
        </>
    );
}
