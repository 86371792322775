import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Blogcard from "Component/Blogcard";
import b1 from "../../assets/images/b1.png";

// blog card data

const blogData = [
  {
    id: 1,
    title:
      "Step into the VR Training Arena: Hone Self-Defense Skills with SetVR-XL Unleashed ",
    author: "John Doe",
    date: "May 15, 2021",
    content:
      "SetVR-XL Unleashed is more than just a virtual reality game; it’s a transformative experience that helps you master the art of self-defense and enhance your overall well-being. By immersing yourself in VR, you can practice martial arts anywhere, anytime. ",
    image: b1,
  },
  // {
  //   id: 2,
  //   title: "Blog Post 2",
  //   author: "John Doe",
  //   date: "May 15, 2021",
  //   content:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod libero non purus tincidunt, at consectetur odio fringilla. In non consectetur ipsum, in posuere mauris. Quisque in ipsum vel felis pharetra consectetur.",
  //   image: "https://via.placeholder.com/300x200",
  // },
  // {
  //   id: 3,
  //   title: "Blog Post 3",
  //   author: "John Doe",
  //   date: "May 15, 2021",
  //   content:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod libero non purus tincidunt, at consectetur odio fringilla. In non consectetur ipsum, in posuere mauris. Quisque in ipsum vel felis pharetra consectetur.",
  //   image: "https://via.placeholder.com/300x200",
  // },
  // {
  //   id: 4,
  //   title: "Blog Post 4",
  //   author: "John Doe",
  //   date: "May 15, 2021",
  //   content:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod libero non purus tincidunt, at consectetur odio fringilla. In non consectetur ipsum, in posuere mauris. Quisque in ipsum vel felis pharetra consectetur.",
  //   image: "https://via.placeholder.com/300x200",
  // },
  // {
  //   id: 5,
  //   title: "Blog Post 5",
  //   author: "John Doe",
  //   date: "May 15, 2021",
  //   content:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod libero non purus tincidunt, at consectetur odio fringilla. In non consectetur ipsum, in posuere mauris. Quisque in ipsum vel felis pharetra consectetur.",
  //   image: "https://via.placeholder.com/300x200",
  // },
  // {
  //   id: 6,
  //   title: "Blog Post 6",
  //   author: "John Doe",
  //   date: "May 15, 2021",
  //   content:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod libero non purus tincidunt, at consectetur odio fringilla. In non consectetur ipsum, in posuere mauris. Quisque in ipsum vel felis pharetra consectetur.",
  //   image: "https://via.placeholder.com/300x200",
  // },
];

const Blog = (props) => {
  return (
    <section className="blog_page">
      <Container>
        <h1 className="blog_title">{props.title}</h1>

        <div className="blog_card">
          <Row>
            {blogData.map((post) => {
              return (
                <Col lg={4} md={6} sm={12} key={post.id}>
                  <Blogcard posts={post} />
                </Col>
              );
            })}
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default Blog;
