import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/style.css";
import MyRouts from "./Routers/routes";
import { SettingsContext } from "context/SettingsProvider";
import { getSettings } from "helpers/backend_helper";

function App() {
  const [, setSettings] = useContext(SettingsContext);

  useEffect(async () => {
    try {
      const data = await getSettings();
      setSettings(data);
      console.log("settings", data);
    } catch (e) {
      console.log("e", e);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>SetVR - XL</title>
        <meta
          name="description"
          content="Welcome to my awesome website homepage."
        />
        <meta property="og:title" content="Home | SetVR - XL" />
        <meta
          property="og:description"
          content="Welcome to my awesome website homepage."
        />
        <meta property="og:url" content="https://setvrxl.com/" />
        <meta
          property="og:image"
          content="https://setvrxl-backend.s3.amazonaws.com/1691738861279sliderthree.png"
        />
      </Helmet>
      <div className="App">
        <ToastContainer />
        <MyRouts />
      </div>
    </>
  );
}

export default App;
