import React from "react";
import { Helmet } from "react-helmet";

import Header from "../Component/Header/Header";
import Home from "Pages/Home/home";
import Whatissetvr from "Pages/Home/whatissetvr";
import Kickstarter from "Pages/Home/kickstarter";
import Funstructure from "Pages/Home/funstructure";
import Excercise from "Pages/Home/excercise";
import Benefits from "Pages/Home/benefits";
import Aboutsetvr from "Pages/Home/aboutsetvr";
import Comingsoon from "Pages/Home/comingsoon";
import Museum from "Pages/Home/museum";
// import Museumaddress from "Pages/Home/museumaddress";
import Testimonial from "Pages/Home/testimonial";
import Contactus from "Pages/Home/contactus";
import Footer from "Component/Footer/Footer";
import Homenew from "Pages/Home/homenew";
import Vediosection from "Pages/Home/vediosection";
import VideoNewSec from "Pages/Home/VideoSec";

export default function Hometheme() {
  return (
    <>
      <Helmet>
        <title>Experience VR Martial Arts Games with SetVR-XL Today!</title>
        <meta
          name="description"
          content="Dive into the excitement of VR martial arts with SetVR-XL. Sharpen your skills, take on intense battles, and experience the ultimate virtual training like never before!"
        />
        <meta property="og:title" content="Home | SetVR - XL" />
        <meta
          property="og:description"
          content="Dive into the excitement of VR martial arts with SetVR-XL. Sharpen your skills, take on intense battles, and experience the ultimate virtual training like never before!"
        />
        <meta property="og:url" content="https://www.mywebsite.com/" />
        <meta
          property="og:image"
          content="https://www.mywebsite.com/homepage-image.jpg"
        />
      </Helmet>
      <Header />
      <Home />
      {/* <Homenew/> */}
      <Whatissetvr />
      <Kickstarter />
      <VideoNewSec />
      <Funstructure />

      {/* <Vediosection/> */}
      <Excercise />
      <Benefits />
      <Aboutsetvr />
      <Comingsoon />
      <Museum />
      {/* <Museumaddress/> */}
      <Testimonial />
      <Contactus />
      <Footer />
    </>
  );
}
